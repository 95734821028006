import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from "components/loader/Loader";

const HomePage = lazy(() => import("pages/HomePage.js"));
const AboutMe = lazy(() => import("pages/AboutMe.js"));
const Testimonials = lazy(() => import("pages/Testimonials.js"));
const Contact = lazy(() => import("pages/Contact.js"));
const NotFound = lazy(() => import("pages/NotFound.js"));

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route path="/loader">
            <Loader />
          </Route>
          <Route path="/sobre-mim">
            <AboutMe />
          </Route>
          <Route path="/depoimentos">
            <Testimonials />
          </Route>
          <Route path="/contato">
            <Contact />
          </Route>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}
